@import "~antd/dist/antd.less";

@font-face {
  font-family: "Avenir-Roman";
  src: local("Avenir-Roman"),
    url("./assets/fonts/AvenirLTStd-Roman.otf") format("woff");
}

@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy800.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Avenir-Roman" !important;
}

.chonky-fileThumbnail{
background-size: cover !important;

}
.previewFile{
  background-color: yellowgreen !important;
}
.content-layout{
 padding: 24px;
}

.field{
  margin-bottom: 12px;

}
.dashboard{
  margin: auto;
  width: 50%;
  margin-top: 7em;
}
.select{
  margin-bottom: 16px;
  width: 100%;
}
.btn.btn-primary{
background-color: black !important;
border: 0;
}
.product-image{
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
  border: 1px solid #e6e7e7 !important;
  border-bottom: 0px !important;
}
@primary-color: #0B7764;